import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from "../components/common"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import ContactUsBtn from "../components/common/ContactUsBtn";

let videoBG = require('../images/page-images/construction-project-management-aerial-photography.jpg')

class BridgeAndInfrastructure extends Component {
    render() {
        return (
            <Layout>

                <div className="container">
                    <h1>
                        Construction Project Management
                    </h1>
                    <div className="row">
                        <div className="col-md-6">
                            <img className={'mbl'} src={videoBG} alt=""/>
                        </div>
                        <div className="col-md-6">
                            <p>
                                From taking the big picture above to improving the efficiency and safety in any construction site or business, drones provide a remarkable way to ensure that your construction project is always the best it can be.  The project team will have the visual history of the construction and excavation progress. For clients who can’t afford to come to the site regularly, drones can be an innovative way to show them the process of the renovation, building or inspection.
                            </p>
                            <ContactUsBtn/>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

BridgeAndInfrastructure.propTypes = {}

export default BridgeAndInfrastructure
